<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>description</md-icon>
                </div>
                <h4 class="title">{{ trans('app.document') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('name')">
                            <label for="name">{{ trans('fields.name') }}</label>
                            <md-input id="name" v-model="form.name"/>
                            <has-error :form="form" field="name"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('file')">
                            <label for="file">{{ trans('fields.file') }}</label>
                            <!--md-file id="file" v-model="form.file"/-->
                            <md-file id="file" v-model="form.file_url" @md-change="onFileChange"/>
                            <has-error :form="form" field="file"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="add()" :disabled="form.loading" id="btnSave">
                            <span v-show="form.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!form.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
    import { api } from "../../../config";
    import Form from "../../../plugins/Form/Form";

    export default {
        created() {
            this.fetchData();
        },

        data() {
            return {
                requiredFields: [
                    "name",
                ],
                form: new Form(),
                loading: false,
            };
        },

        methods: {
            fetchData() {
                var url = api.domain + "document/";
                if (this.$route.params.id) {
                    url += this.$route.params.id + "/edit";
                } else {
                    url += "create";
                }
                axios.get(url).then(response => {
                    this.form = new Form(response.data.document);
                    this.form.requiredFields = this.requiredFields;
                }) .catch(function(error) {
                    console.log("An error occured obtaining document info" + error);
                });
            },

            onFileChange(files) {
              if (files.length) {
                this.form.file = files[0];
              }
            },

            async add() {
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };
                var response;

                try {
                    if (this.form.id != null && this.form.id != '') {
                        // Edition
                        response = await this.form.put(api.domain + 'document/'+this.form.id, config);
                    } else {
                        // Creation
                        response = await this.form.post(api.domain + 'document', config);
                    }

                    if (response.data.success) {
                        this.$noty.success(this.trans('common.success.saving'));
                        this.$router.push({ name: 'documents' });
                    } else {
                        this.$noty.error(response.data.msge);
                    }
                } catch(err) {
                    if (err.response && err.response.data.error) {
                        this.$noty.error(this.trans('common.'+err.response.data.error));
                    } else {
                        console.error('an error occured: ', err);
                        this.$noty.error(this.trans('common.error.saving'));
                    }
                }
            }
        }
    };
</script>