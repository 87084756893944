<template>
	<div class="md-layout-item md-small-size-100 md-size-100">
		<form @submit.prevent="login">
			<md-field :class="form.getFieldClass('email')">
				<label for="email">{{ trans('fields.email') }}</label>
				<md-input
					type="email"
					id="email"
					v-model="form.email"
					autocomplete="off"
					:disabled="form.loading"
				/>
				<has-error :form="form" field="email"/>
			</md-field>

			<md-field :class="form.getFieldClass('password')">
				<label for="password">{{ trans('fields.password') }}</label>
				<md-input
					type="password"
					class="form-control"
					id="password"
					v-model="form.password"
					:disabled="form.loading"
				/>
				<has-error :form="form" field="password"/>
			</md-field>

			<md-checkbox v-model="form.remember" id="chkRemember">{{ trans('common.remember_me') }}</md-checkbox>

			<div class="form-group">
				<md-button type="submit" class="btn btn-primary btn-block md-primary" id="btnLogin" :disabled="form.loading">
					<span v-show="form.loading">{{ trans('common.logging_in') }}</span>
					<span v-show="!form.loading">{{ trans('common.login') }}</span>
				</md-button>				
			</div>

			<router-link :to="{name: 'forgotten-password'}" id="lnkForgotten">{{ trans('common.forgotten_password') }}</router-link>
		</form>
	</div>
</template>

<script>
	import {api} from "../../../config";
	import Form from "../../../plugins/Form/Form";

	export default {
		data() {
			return {
				form: new Form({
					email: null,
					password: null,
					remember: false
				}),					

			}
		},
		methods: {
			async login() {
				// Submit the form
				try {
					const { data } = await this.form.post(api.login);
					this.$emit('loginSuccess', data);			
				} catch(err) {
					(err.response.data.error) && this.$noty.error(this.trans('common.'+err.response.data.error));
				}
			}
		}
	}
</script>
