<template>
	<div class="md-layout-item md-small-size-100 md-size-100">
		<form @submit.prevent="sendReset">
			<md-field :class="form.getFieldClass('email')">
				<label for="email">{{ trans('fields.email') }}</label>
				<md-input
					type="email"
					id="email"
					v-model="form.email"
					autocomplete="off"
					:disabled="form.loading"
				/>
				<has-error :form="form" field="email"/>
			</md-field>

			<div class="form-group">
				<md-button type="submit" class="btn btn-primary btn-block md-primary" id="btnRemember" :disabled="form.loading">
					<span v-show="form.loading">{{ trans('common.sending') }}</span>
					<span v-show="!form.loading">{{ trans('common.password_reset_link') }}</span>
				</md-button>
			</div>
		</form>
	</div>
</template>

<script>
	import {api} from "../../../../config";
	import Form from "../../../../plugins/Form/Form";

	export default {
		data() {
			return {
				form: new Form({
					email: null
				}),
			}
		},
		methods: {
			async sendReset() {
				try {
					const { data } = await this.form.post(api.remember);
					if (data.status) {
						this.$noty.success(data.status);
					} else {
						this.$noty.success(this.trans('passwords.sent'));
					}
					this.$emit('rememberSuccess', data);				
				} catch(err) {
					if (err.response && err.response.data && err.response.data.email) {
						// Email not registered, but we don't say anything
						this.$noty.success(this.trans('passwords.sent'));							
						this.$emit('rememberSuccess', {});
					} else {
						if (err.response.status!=422) {
							this.$noty.error(this.trans('common.error.sending_mail'));
						}
					}
				}			
			},		
		}
	}
</script>
