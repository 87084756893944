<template>
  <footer class="footer">
    <div class="container">
        <nav>
            <ul>
              <li>
                <router-link :to="{path:'/'}">{{ trans('common.home') }}</router-link>
              </li>
              <!--
                <li>
                    <a href="#">
                        Company
                    </a>
                </li>
                <li>
                    <a href="#">
                        Portfolio
                    </a>
                </li>
                <li>
                    <a href="#">
                        Blog
                    </a>
                </li>
            -->
            </ul>
        </nav>
        <div class="copyright text-center">
          &copy; {{ new Date().getFullYear() }} <a href="http://www.fiveflamesmobile.com/" target="_blank">Five Flames Mobile</a>
        </div>
    </div>
  </footer>

</template>
<script>
export default {}

</script>
<style>

</style>
