<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>newspaper</md-icon>
                </div>
                <h4 class="title">
                    {{ trans('app.news') }}
                    <md-progress-spinner v-if="loading" :md-stroke="1" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100 label-for-filter">
                        <span>{{trans('common.filter_by_category')}}:</span>
                        <md-button :class="[currentCategory == null ? 'md-primary' : 'md-secondary']" @click="filterCategory(null)" :disabled="loading" id="btnfilterCategoryAll">
                            <span>{{ trans('common.all') }}</span>
                        </md-button>

                        <md-button :class="[currentCategory === category.code ? 'md-primary' : 'md-secondary']" v-for="(category) in categories" :key='category.code' @click="filterCategory(category.code)" :disabled="loading">
                            <span>{{ category.description }}</span>
                        </md-button>
                    </div>
                </div>

                <div class="md-layout">
                    <div class="md-layout-item md-size-100 label-for-filter">
                        <span>{{trans('common.filter_by_status')}}:</span>
                        <md-button :class="[currentStatus == null ? 'md-primary' : 'md-secondary']" @click="filterStatus(null)" :disabled="loading" id="btnFilterStatusAll">
                            <span>{{ trans('common.all') }}</span>
                        </md-button>

                        <md-button :class="[currentStatus == true ? 'md-primary' : 'md-secondary']" @click="filterStatus(true)" :disabled="loading">
                            <span>{{ trans('app.published') }}</span>
                        </md-button>

                        <md-button :class="[currentStatus == false ? 'md-primary' : 'md-secondary']" @click="filterStatus(false)" :disabled="loading">
                            <span>{{ trans('app.scheduled') }}</span>
                        </md-button>
                    </div>
                </div>

                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table v-model="news.data" class="paginated-table table-striped table-hover" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">

                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{name: 'newsItem'}">
                                        <md-button class="btn btn-primary md-primary" id="btnAdd">
                                            <div class="float-left" >
                                                <md-icon>add</md-icon>
                                            </div>
                                            &nbsp; {{ trans('common.add') }}
                                        </md-button>
                                    </router-link>
                                </div>
                                <md-field md-clearable class="md-toolbar-section-end">
                                    <md-input id="txt_search" name="txt_search" :placeholder="trans('common.txt_search')" v-model="txt_search" @input="fetchNews()">
                                    </md-input>
                                </md-field>
                            </md-table-toolbar>

                            <md-table-empty-state
                                :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
                                <md-table-cell :md-label="trans('app.state')" md-sort-by="scheduled">
                                    <md-icon v-if="item.scheduled">hourglass_empty</md-icon>
                                    <md-icon v-else>done</md-icon>
                                    <md-tooltip md-direction="top">{{ item.state }}</md-tooltip>
                                </md-table-cell>
                                <md-table-cell :md-label="trans('fields.highlight')" md-sort-by="highlight_end_date">
                                    <md-icon v-if="item.highlight">star</md-icon>
                                    <md-tooltip v-if="item.highlight" md-direction="top">{{ trans('fields.highlight') }}</md-tooltip>
                                </md-table-cell>
                                <md-table-cell :md-label="trans('fields.type')" md-sort-by="category">{{ item.category.description }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.date')" md-sort-by="timestamp">{{ item.timestamp }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.title')" md-sort-by="title">{{ item.title }}</md-table-cell>
                                <md-table-cell :md-label="trans('common.actions')">
                                    <router-link :to="{name: 'newsItem', params: { id: item.id }}" :title="trans('common.edit')" id="lnkEdit">
                                        <md-icon class="md-primary">edit</md-icon>
                                    </router-link>
                                    <!--b-link v-if="item.email==currentUser.email" :title="trans('common.forbidden')" id="lnkEdit" disabled>
                                        <md-icon>delete</md-icon>
                                    </b-link-->
                                    <b-link :title="trans('common.delete')" @click="confirmDeleteNews(item, index)" id="lnkDelete">
                                        <md-icon class="md-primary">delete</md-icon>
                                    </b-link>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>

                    <div class="mx-auto" style="margin-top: 18px;">
                        <pagination ref="pag" :data="news" :limit=10 @pagination-change-page="fetchNews"></pagination>
                    </div>
                </div>
            </md-card-content>
        </md-card>

        <md-dialog-confirm
            :md-active.sync="showConfirmDelete"
            :md-title="trans('common.confirmation')"
            :md-content="confirmDeleteContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="deleteNews" />
    </div>
</template>
<script>
import tableEmptyText from "../../../mixins/tableEmptyText";
import {api} from "../../../config";
import {mapState} from 'vuex';

export default {
    mixins: [
        tableEmptyText,
    ],

    data() {
        return {
            news: {
                current_page:1,
                data:[]
            },
            selectedNews: {},
            selectedIndex: 0,
            txt_search: "",
            showConfirmDelete: false,
            confirmDeleteContent: this.trans('app.confirm.delete_news', {user: ''}),
            currentSort: 'timestamp',
            currentSortOrder: 'desc',
            emptyText: this.trans('app.no_results', {item: this.trans('app.news')}),
            loading: false,
            currentCategory: null,
            categories: [],
            currentStatus: null,
        }
    },

    computed: mapState({
        currentUser: state => state.auth
    }),

    watch: {
      selectedNews: function (news) {
        this.confirmDeleteContent =  this.trans('app.confirm.delete_news', {news: this.selectedNews.title});
      }
    },

    methods: {
        async filterCategory(category=null) {
            this.currentCategory = category;
            this.fetchNews();
        },

        async filterStatus(status=null) {
            this.currentStatus = status;
            this.fetchNews();
        },

        async fetchNews(page=1){
            this.loading = true;
            axios.post(api.domain + 'news-list?page='+page, {txt_search: this.txt_search, sort_field: this.currentSort, order: this.currentSortOrder, category: this.currentCategory, status: this.currentStatus})
            .then((response) => {
                this.news = response.data.news;
                this.categories = response.data.categories;
                this.loading = false;
            })
            .catch((error) => {
                console.log('An error ocurred ' + error);
                this.loading = false;
            });
        },

        clearFilters(){
            this.txt_search="";
            this.fetchNews();
        },

        confirmDeleteNews(user, key) {
            this.selectedNews = user;
            this.selectedIndex = key;
            this.showConfirmDelete = true;
        },

        deleteNews() {
            let id = this.selectedNews.id;
            let key = this.selectedIndex;
            let uri = api.domain + 'news/' + id;
            this.loading = true;
            axios.delete(uri).then((response) => {
                this.news.data.splice(key, 1);
                if (this.news.data.length == 0) {
                    this.fetchNews(this.$refs.pag.data.current_page - 1);
                }
                this.loading = false;
            }).catch(error => {
                // handle error
                this.loading = false;
                if (error.response.status == 400) {
                    console.log('an error occured ' + error.response.data.message);
                } else {
                    console.log('an error occured ' + error);
                }
            });
        },

        customSort: function customSort(value) {
            this.fetchNews();

            return value;
        }
    }
}
</script>
