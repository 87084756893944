<template>
	<div class="container-fluid">
		<div class="row" style="padding-top: 5%;">
			<div class="md-layout-item md-small-size-100 md-size-35 col-8 offset-4">
				<md-card>
					<md-card-header v-if="logo" class="md-card-header-image">
						<div class="card-image">
							<img :src="logo" :alt="siteName" class="img-fluid"/>
						</div>
					</md-card-header>

					<md-card-header v-else class="md-card-header-icon md-card-header-primary">
						<div class="card-icon">
						    <md-icon>lock</md-icon>
						</div> 	
						<h4 class="title">{{siteName}}</h4>
					</md-card-header>

					<md-card-content>
						<div class="md-layout">
							<login-form @loginSuccess="loginSuccess"></login-form>
						</div>
					</md-card-content>
				</md-card>				
			</div>
		</div>
	</div>
</template>

<script>
	import {siteName} from './../../../config';
	import LoginForm from './LoginForm.vue'
	import {mapActions} from "vuex";

	export default {
		data() {
			return {
				siteName: siteName,
				logo: ""
			}
		},		
		components: {
			'login-form': LoginForm
		},
		methods: {
			...mapActions([
				'setAuthUser',
				'setToken'
			]),
			loginSuccess(data) {
				this.setToken(data.access_token);
				this.setAuthUser(data.user);
				this.$router.push({name: 'index'});
			}
		}
	}
</script>
