var tableEmptyText = {
    watch: {
        loading: function(loading) {
            var text = '';
            if (loading) {
                text = this.trans('common.loading');
            } else {
                text = this.emptyText;
            }

            var emptyLabel = document.querySelector(".md-empty-state-label");
            if (emptyLabel!=null) {
                emptyLabel.innerHTML = text;
            }
        }
    }
}

export default tableEmptyText;