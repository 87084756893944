import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './store/index';
import routes from './routes';

import {api} from "./config";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
	mode: 'history',
	routes,
	linkExactActiveClass: 'nav-item active'
});

router.beforeEach(async (to, from, next) => {
	// Scroll top on page loading
    router.app.$root.$emit('reset-scroll-dashboard-layout');

	// just logged in: localStorage has token, but state does not have auth user
	if (store.getters.getToken && !store.getters.isLoggedIn) {
		let {data: authUser} = await axios.get(api.currentUser);
		await store.dispatch('setAuthUser', authUser);
	}

	if (to.meta.requiresAuth) {
		if (store.getters.isLoggedIn || store.getters.getToken)
			return next();
		else
			return next({name: 'login'});
	}
	if (to.meta.requiresGuest) {
		if (store.getters.isLoggedIn || store.getters.getToken)
			return next({name: 'index'});
		else
			return next();
	}
	next();
});

export default router;