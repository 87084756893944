<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>settings</md-icon>
                </div>
                <h4 class="title">{{ trans('app.configuration') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <md-subheader>{{ trans('app.employee_directory') }}</md-subheader>
                    <div class="md-layout-item md-size-100">
                        <md-field :class="configuration.getFieldClass('employee_directory_url')">
                            <md-input id="employee_directory_url" v-model="configuration.employee_directory_url" :placeholder="trans('fields.employee_directory_url')"/>
                            <has-error :form="configuration" field="employee_directory_url"/>
                        </md-field>
                    </div>

                    <md-subheader>{{ trans('app.rrss') }}</md-subheader>
                    <div class="md-layout-item md-size-100">
                        <md-field :class="configuration.getFieldClass('instagram_url')">
                            <label for="instagram_url">{{ trans('fields.instagram_url') }}</label>
                            <md-input type="instagram_url" id="instagram_url" v-model="configuration.instagram_url" :placeholder="trans('app.rrss_account_url', {rrss: trans('fields.instagram_url')})"/>
                            <has-error :form="configuration" field="instagram_url"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="configuration.getFieldClass('facebook_url')">
                            <label for="facebook_url">{{ trans('fields.facebook_url') }}</label>
                            <md-input type="facebook_url" id="facebook_url" v-model="configuration.facebook_url" :placeholder="trans('app.rrss_account_url', {rrss: trans('fields.facebook_url')})"/>
                            <has-error :form="configuration" field="facebook_url"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="configuration.getFieldClass('twitter_url')">
                            <label for="twitter_url">{{ trans('fields.twitter_url') }}</label>
                            <md-input type="twitter_url" id="twitter_url" v-model="configuration.twitter_url" :placeholder="trans('app.rrss_account_url', {rrss: trans('fields.twitter_url')})"/>
                            <has-error :form="configuration" field="twitter_url"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="configuration.getFieldClass('youtube_url')">
                            <label for="youtube_url">{{ trans('fields.youtube_url') }}</label>
                            <md-input type="youtube_url" id="youtube_url" v-model="configuration.youtube_url" :placeholder="trans('app.rrss_account_url', {rrss: trans('fields.youtube_url')})"/>
                            <has-error :form="configuration" field="youtube_url"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="configuration.getFieldClass('linkedin_url')">
                            <label for="linkedin_url">{{ trans('fields.linkedin_url') }}</label>
                            <md-input type="linkedin_url" id="linkedin_url" v-model="configuration.linkedin_url" :placeholder="trans('app.rrss_account_url', {rrss: trans('fields.linkedin_url')})"/>
                            <has-error :form="configuration" field="linkedin_url"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="configuration.getFieldClass('tiktok_url')">
                            <label for="tiktok_url">{{ trans('fields.tiktok_url') }}</label>
                            <md-input type="tiktok_url" id="tiktok_url" v-model="configuration.tiktok_url" :placeholder="trans('app.rrss_account_url', {rrss: trans('fields.tiktok_url')})"/>
                            <has-error :form="configuration" field="tiktok_url"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="configuration.getFieldClass('spotify_url')">
                            <label for="spotify_url">{{ trans('fields.spotify_url') }}</label>
                            <md-input type="spotify_url" id="spotify_url" v-model="configuration.spotify_url" :placeholder="trans('app.rrss_account_url', {rrss: trans('fields.spotify_url')})"/>
                            <has-error :form="configuration" field="spotify_url"/>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="save()" :disabled="configuration.loading" id="btnSave">
                            <span v-show="configuration.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!configuration.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
    import { api } from "../../../config";
    import Form from "../../../plugins/Form/Form";

    export default {
        mounted() {
            this.fetchData();
        },

        data() {
            return {
                requiredFields: [],
                configuration: new Form(),
                loading: false,
            };
        },

        methods: {
            fetchData() {
                var url = api.domain + "configuration";
                axios.get(url).then(response => {
                    this.configuration = new Form(response.data.configuration);
                    this.configuration.requiredFields = this.requiredFields;
                }) .catch(function(error) {
                    console.log("An error occured obtaining configuration info" + error);
                });
            },

            async save() {
                var response;

                try {
                    //if (this.configuration.id != null && this.configuration.id != '') {
                        // Edition
                        response = await this.configuration.put(api.domain + 'configuration/'+this.configuration.id);
                    /*} else {
                        // Creation
                        response = await this.configuration.post(api.domain + 'configuration');
                    }*/

                    if (response.data.success) {
                        this.$noty.success(this.trans('common.success.saving'));
                        //this.$router.push({ name: 'configurations'});
                    } else {
                        this.$noty.error(response.data.msge);
                    }
                } catch(err) {
                    if (err.response && err.response.data.error) {
                        this.$noty.error(this.trans('common.'+err.response.data.error));
                    } else {
                        console.log('an error occured ' + err);
                        this.$noty.error(this.trans('common.error.saving'));
                    }
                }
            },
        }
    };
</script>