import Vue from 'vue';
import Vuex from 'vuex';
import auth from "./modules/auth";
import createPersistedState from 'vuex-persistedstate'
//import * as Cookies from 'js-cookie'
import SecureLS from "secure-ls";
const ls = new SecureLS();

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			storage: {
				getItem: key => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: key => ls.remove(key)
			}			
			/*
			storage: {
				getItem: key => Cookies.get(key),
				setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
				removeItem: key => Cookies.remove(key)
			}
			*/
		})
	],
	modules: {
		auth
	},
	strict: true
});