<template>
	<div class="container-fluid">
		<div class="row" style="padding-top: 5%;">
			<div class="md-layout-item md-small-size-100 md-size-35 col-8 offset-4">
				<md-card>
					<md-card-header v-if="logo" class="md-card-header-image">
						<div class="card-image">
							<img :src="logo" :alt="siteName" class="img-fluid"/>
						</div>
					</md-card-header>

					<md-card-header v-else class="md-card-header-icon md-card-header-primary">
						<div class="card-icon">
						    <md-icon>lock</md-icon>
						</div>
						<h4 class="title">{{siteName}}</h4>
					</md-card-header>

					<md-card-content>
						<div class="md-layout">
                            <!-- Additional Content -->
                            <div class="md-layout-item">
                                <br />
                                {{ trans('passwords.download_app') }}
                                <br />
                            </div>
                        </div>
						<div class="md-layout" style="height: 100px;">
                            <!-- Left Column (App Store Logo) -->
                            <div class="md-layout-item" style="flex: 1; display: flex; align-items: center; justify-content: center;">
                                <a href="https://apps.apple.com/es/app/cuatro-rayas/id1321354494" style="text-decoration: none; text-align: center;">
                                    <img src="/img/badge-apple.png" alt="App Store" style="width: 160px; height: 54px;">
                                </a>
                            </div>

                            <!-- Right Column (Android Play Store Logo) -->
                            <div class="md-layout-item" style="flex: 1; display: flex; align-items: center; justify-content: center;">
                                <a href="https://play.google.com/store/apps/details?id=com.vitalinnova.cuatrorayas" style="text-decoration: none; text-align: center;">
                                    <img src="img/badge-android.png" alt="Play Store" style="width: 160px; height: 54px;">
                                </a>
                            </div>
                        </div>
					</md-card-content>
				</md-card>
			</div>
		</div>
	</div>
</template>

<script>
	import {siteName} from './../../../../config';
	import {mapActions} from "vuex";

	export default {
		data() {
			return {
				siteName: siteName,
				logo: ""
			}
		},
		components: {
		},
		methods: {
			...mapActions([
				'setAuthUser',
				'setToken'
			]),
		}
	}
</script>
