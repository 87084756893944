<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <md-card :class="same_height_400">
                    <md-card-header class="md-card-header-icon md-card-header-primary">
                        <div class="card-icon">
                            <md-icon>article</md-icon>
                        </div>
                        <h4 class="title">{{ trans('app.last_news') }}</h4>
                    </md-card-header>
                    <md-card-content>
                        <md-table v-model="news.data" class="paginated-table table-striped table-hover">
                            <!-- We hide this by now
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{name: 'news'}">
                                        <md-button class="btn btn-primary md-primary" id="btnNews">
                                            <div class="float-left" >
                                                <md-icon>list</md-icon>
                                            </div>
                                            &nbsp; {{ trans('app.news') }}
                                        </md-button>
                                    </router-link>
                                </div>
                            </md-table-toolbar>
                            -->

                            <md-table-empty-state
                                :md-label="emptyTextNews">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell :md-label="trans('fields.title')">{{ item.title | truncate(110) }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.description')">{{ item.category.description }}</md-table-cell>
                                <md-table-cell :md-label="trans('common.actions')">
                                    <router-link :to="{name: 'newsItem', params: { id: item.id }}" :title="trans('common.edit')" id="lnkNewsEdit">
                                        <md-icon class="md-primary">edit</md-icon>
                                    </router-link>
                                </md-table-cell>
                            </md-table-row>
                            </md-table>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item">
                <md-card :class="same_height_400">
                    <md-card-header class="md-card-header-icon md-card-header-primary">
                        <div class="card-icon">
                            <md-icon>sticky_note_2</md-icon>
                        </div>
                        <h4 class="title">{{ trans('app.last_notices') }}</h4>
                    </md-card-header>
                    <md-card-content>
                        <md-table v-model="notices.data" class="paginated-table table-striped table-hover">
                            <!-- We hide this by now
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{name: 'notices'}">
                                        <md-button class="btn btn-primary md-primary" id="btnNotices">
                                            <div class="float-left" >
                                                <md-icon>list</md-icon>
                                            </div>
                                            &nbsp; {{ trans('app.notices') }}
                                        </md-button>
                                    </router-link>
                                </div>
                            </md-table-toolbar>
                            -->
                            <md-table-empty-state
                                :md-label="emptyTextNotices">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell :md-label="trans('fields.title')">{{ item.title | truncate(110)  }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.description')">{{ item.description }}</md-table-cell>
                                <md-table-cell :md-label="trans('common.actions')">
                                    <router-link :to="{name: 'noticesItem', params: { id: item.id }}" :title="trans('common.edit')" id="lnkNoticesEdit">
                                        <md-icon class="md-primary">edit</md-icon>
                                    </router-link>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <md-card :class="same_height_400">
                    <md-card-header class="md-card-header-icon md-card-header-primary">
                        <div class="card-icon">
                            <md-icon>person</md-icon>
                        </div>
                        <h4 class="title">{{ trans('app.users_pending') }}</h4>
                    </md-card-header>
                    <md-card-content>
                        <md-table v-model="users.data" class="paginated-table table-striped table-hover">

                            <!-- We hide this by now
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{name: 'users'}">
                                        <md-button class="btn btn-primary md-primary" id="btnUsers">
                                            <div class="float-left" >
                                                <md-icon>list</md-icon>
                                            </div>
                                            &nbsp; {{ trans('app.users') }}
                                        </md-button>
                                    </router-link>
                                </div>
                            </md-table-toolbar>
                            -->
                            <md-table-empty-state
                                :md-label="emptyTextUsers">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell :md-label="trans('fields.name')">{{ item.name }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.email')">{{ item.email }}</md-table-cell>
                                <md-table-cell :md-label="trans('common.actions')">
                                    <router-link :to="{name: 'user', params: { id: item.id }}" :title="trans('common.edit')" id="lnkUserEdit">
                                        <md-icon class="md-primary">edit</md-icon>
                                    </router-link>
                                </md-table-cell>
                            </md-table-row>
                            </md-table>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item">
                <md-card :class="same_height_400">
                    <md-card-header class="md-card-header-icon md-card-header-primary">
                        <div class="card-icon">
                            <md-icon>source</md-icon>
                        </div>
                        <h4 class="title">{{ trans('app.documents') }}</h4>
                    </md-card-header>
                    <md-card-content>
                        <md-table v-model="documents.data" class="paginated-table table-striped table-hover">
                            <!-- We hide this by now
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{name: 'documents'}">
                                        <md-button class="btn btn-primary md-primary" id="btnDocuments">
                                            <div class="float-left" >
                                                <md-icon>list</md-icon>
                                            </div>
                                            &nbsp; {{ trans('app.documents') }}
                                        </md-button>
                                    </router-link>
                                </div>
                            </md-table-toolbar>
                            -->
                            <md-table-empty-state
                                :md-label="emptyTextDocuments">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell :md-label="trans('fields.name')">
                                    <a :href="item.file_url" target="_blank">
                                    {{ item.name }}<br/>{{item.file_url}}
                                    </a>
                                </md-table-cell>
                                <md-table-cell :md-label="trans('common.actions')">
                                    <router-link :to="{name: 'document', params: { id: item.id }}" :title="trans('common.edit')" id="lnkEdit">
                                        <md-icon class="md-primary">edit</md-icon>
                                    </router-link>
                                </md-table-cell>
                            </md-table-row>
                            </md-table>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
    import tableEmptyText from "../../mixins/tableEmptyText";
	import {siteName, api} from './../../config';
	import {mapGetters, mapState} from 'vuex';
	import 'vuejs-noty/dist/vuejs-noty.css'

	export default {
        mixins: [
            tableEmptyText,
        ],
		data() {
			return {
				siteName: siteName,
                users: {
                    data: []
                },
                news: {
                    data: []
                },
                notices: {
                    data: []
                },
                documents: {
                    data: []
                },
                emptyTextUsers: this.trans('app.no_results', {item: this.trans('app.users_pending')}),
                emptyTextNews: this.trans('app.no_results', {item: this.trans('app.news')}),
                emptyTextNotices: this.trans('app.no_results', {item: this.trans('app.notices')}),
                emptyTextDocuments: this.trans('app.no_results', {item: this.trans('app.documents')}),

                loading: false,
                same_height_400: 'md-same-height-400',
			}
		},
		/*computed: mapGetters([
			'isLoggedIn'
		]),*/
        mounted() {
            this.fetchUsers();
            this.fetchNews();
            this.fetchNotices();
            this.fetchDocuments();
        },
		methods: {
            async fetchUsers(){
                this.loading = true;
                axios.get(api.domain + 'users/pending')
                .then((response) => {
                    this.users.data = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log('An error ocurred ' + error);
                    this.loading = false;
                });
            },
            async fetchNews(){
                this.loading = true;
                axios.get(api.domain + 'last-news')
                .then((response) => {
                    this.news.data = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log('An error ocurred ' + error);
                    this.loading = false;
                });
            },
            async fetchNotices(){
                this.loading = true;
                axios.get(api.domain + 'last-notices')
                .then((response) => {
                    this.notices.data = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log('An error ocurred ' + error);
                    this.loading = false;
                });
            },
            async fetchDocuments(){
                this.loading = true;
                axios.get(api.domain + 'last-documents')
                .then((response) => {
                    this.documents.data = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log('An error ocurred ' + error);
                    this.loading = false;
                });
            },
			/*logout() {
				this.$store.dispatch('unsetAuthUser')
					.then(() => {
						this.$noty.success(this.trans('common.logged_out'));
						this.$router.push({name: 'login'});
					});
			}*/
		}

	}
</script>

<style scoped>
.md-card .md-card-content {
    overflow: auto;
}
</style>
