import Login from './components/pages/auth/Login.vue';
import PasswordReset from './components/pages/auth/password/PasswordReset.vue';
import Logout from './components/pages/auth/Logout.vue';
import RememberPassword from './components/pages/auth/password/RememberPassword.vue';
import ResetPassword from './components/pages/auth/password/ResetPassword.vue';
import DashboardLayout from './components/pages/Layout/DashboardLayout.vue';
import Dashboard from './components/pages/Dashboard.vue';
import Users from './components/pages/users/List.vue';
import User from './components/pages/users/Form.vue';
import News from './components/pages/news/List.vue';
import NewsItem from './components/pages/news/Form.vue';
import Documents from './components/pages/documents/List.vue';
import Document from './components/pages/documents/Form.vue';
import Configuration from './components/pages/configuration/Form.vue';
import Stats from './components/pages/stats/List.vue';
import Profile from './components/pages/users/Profile.vue';
import Common from './components/pages/common/List.vue';
import CommonItem from './components/pages/common/Form.vue';

export default [
	{
		path: '/',
		name: 'index',
		component: DashboardLayout,
    	redirect: '/dashboard',
		meta: {requiresAuth: true},
		children: [
			{
				path: 'dashboard',
				name: 'dashboard',
				component: Dashboard,
				meta: {requiresAuth: true},
			},
			{
				path: 'users',
				name: 'users',
				component: Users,
				meta: {requiresAuth: true},
			},
			{
				path: 'user/:id?',
				name: 'user',
				component: User,
				meta: {requiresAuth: true},
			},
			{
				path: 'profile',
				name: 'profile',
				component: Profile,
				meta: {requiresAuth: true},
			},
			{
				path: 'configuration/:id?',
				name: 'configuration',
				component: Configuration,
				meta: {requiresAuth: true},
			},
			{
				path: 'news',
				name: 'news',
				component: Common,
                props: { model: 'App\\News', },
				meta: {requiresAuth: true},
			},
			{
				path: 'news/:id?',
				name: 'newsItem',
				component: CommonItem,
                props: { model: 'App\\News', },
				meta: {requiresAuth: true},
			},
			{
				path: 'notices',
				name: 'notices',
				component: Common,
                props: { model: 'App\\Notice', },
				meta: {requiresAuth: true},
			},
			{
				path: 'notices/:id?',
				name: 'noticesItem',
				component: CommonItem,
                props: { model: 'App\\Notice', },
				meta: {requiresAuth: true},
			},
			{
				path: 'ads',
				name: 'ads',
				component: Common,
                props: { model: 'App\\Ad', },
				meta: {requiresAuth: true},
			},
			{
				path: 'ads/:id?',
				name: 'adsItem',
				component: CommonItem,
                props: { model: 'App\\Ad', },
				meta: {requiresAuth: true},
			},
			{
				path: 'documents',
				name: 'documents',
				component: Documents,
				meta: {requiresAuth: true},
			},
			{
				path: 'document/:id?',
				name: 'document',
				component: Document,
				meta: {requiresAuth: true},
			},
			{
				path: 'stats',
				name: 'stats',
				component: Stats,
				meta: {requiresAuth: true},
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {requiresGuest: true}
	},
	{
		path: '/password-reset',
		name: 'password-reset',
		component: PasswordReset,
		meta: {requiresGuest: true}
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		meta: {requiresAuth: true}
	},
	{
		path: '/password/reset/:token',
		name: 'reset-password',
		component: ResetPassword,
		meta: {requiresGuest: true}
	},
	{
		path: '/password/reset',
		name: 'forgotten-password',
		component: RememberPassword,
		meta: {requiresGuest: true}
	}
]
