<template>
    <div
      class="wrapper"
      :class="[
        { 'nav-open': $sidebar.showSidebar },
        { rtl: $route.meta.rtlActive }
      ]"
    >

    <notifications></notifications>

    <side-bar
      :title="appName"
      :siteUrl="siteUrl"
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <!--user-menu></user-menu-->
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          :link="{ name: this.trans('common.dashboard'), icon: 'space_dashboard', path: '/dashboard', id: 'lnkDashboard' }"
        >
        </sidebar-item>

        <sidebar-item v-if="hasAnyRole(['admin'])"
          :link="{ name: this.trans('routes.news'), icon: 'article', path: '/news', id: 'lnkNews' }"
        >
        </sidebar-item>

        <sidebar-item v-if="hasAnyRole(['admin'])"
          :link="{ name: this.trans('routes.notices'), icon: 'sticky_note_2', path: '/notices', id: 'lnkNotices' }"
        >
        </sidebar-item>

        <sidebar-item v-if="hasAnyRole(['admin'])"
          :link="{ name: this.trans('routes.ads'), icon: 'view_kanban', path: '/ads', id: 'lnkAds' }"
        >
        </sidebar-item>

        <sidebar-item v-if="hasAnyRole(['admin', 'lopd', 'tienda'])"
          :link="{ name: this.trans('routes.users'), icon: 'manage_accounts', path: '/users', id: 'lnkUsers' }"
        >
        </sidebar-item>

        <sidebar-item v-if="hasAnyRole(['admin'])"
          :link="{ name: this.trans('routes.documents'), icon: 'source', path: '/documents', id: 'lnkDocuments' }"
        >
        </sidebar-item>

        <sidebar-item v-if="hasAnyRole(['admin'])"
          :link="{ name: this.trans('routes.stats'), icon: 'assessment', path: '/stats', id: 'lnkStats' }"
        >
        </sidebar-item>

        <sidebar-item v-if="hasAnyRole(['admin', 'lopd'])"
        :link="{ name: this.trans('routes.configuration'), icon: 'display_settings', path: '/configuration', id: 'lnkConfiguration' }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{ name: this.trans('common.logout'), icon: 'power_settings_new', path: '/logout', id: 'lnkLogout' }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <!--
      <fixed-plugin
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>
    -->

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view :key="$route.fullPath"></router-view>
        </zoom-center-transition>
      </div>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
    $scaleSize: 0.95;
    @keyframes zoomIn95 {
      from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
      }
      to {
        opacity: 1;
      }
    }
    .main-panel .zoomIn {
      animation-name: zoomIn95;
    }
    @keyframes zoomOut95 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
      }
    }
    .main-panel .zoomOut {
      animation-name: zoomOut95;
    }
</style>
<script>
    /* eslint-disable no-new */
    import PerfectScrollbar from "perfect-scrollbar";
    import "perfect-scrollbar/css/perfect-scrollbar.css";

    function hasElement(className) {
      return document.getElementsByClassName(className).length > 0;
    }

    function initScrollbar(className) {
      if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
        var ps_container = document.querySelector(`.${className}`);
      } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
          initScrollbar(className);
        }, 100);
      }
      return ps_container;
    }

    import TopNavbar from './TopNavbar.vue'
    import ContentFooter from './ContentFooter.vue'
    import MobileMenu from './Extra/MobileMenu.vue'
    //import UserMenu from './Extra/MobileMenu.vue'
    import FixedPlugin from "./FixedPlugin.vue";
    import { ZoomCenterTransition } from "vue2-transitions";

    import {api} from "../../../config";
    import {mapGetters, mapState} from 'vuex';

    export default {
      mounted() {
          let docClasses = document.body.classList;
          let isWindows = navigator.platform.startsWith("Win");
          if (isWindows) {
              // if we are on windows OS we activate the perfectScrollbar function
              initScrollbar("sidebar");
              initScrollbar("sidebar-wrapper");
              this.perfectScrollMainPanelContainer = initScrollbar("main-panel");

              docClasses.add("perfect-scrollbar-on");
          } else {
              docClasses.add("perfect-scrollbar-off");
          }

          this.$root.$on('reset-scroll-dashboard-layout', this.scrollToTop);

          this.initMaterialLocale();
      },

      watch: {
          sidebarMini() {
              this.minimizeSidebar();
          }
      },

      data() {
          return {
              appName: window.Laravel.siteName,
              siteUrl: window.Laravel.siteUrl,

              sidebarBackgroundColor: "black",
              sidebarBackground: "purple",
              sidebarBackgroundImage: "./img/fondo1.jpg",
              sidebarMini: true,
              sidebarImg: true,
              perfectScrollMainPanelContainer:null
          }
      },

      computed: {
        ...mapState({
            currentUser: state => state.auth
        }),

        ...mapGetters([
            'hasAnyRole'
        ]),

        checkRole: function(roles) {
            return this.hasAnyRole(roles);
        }
      },

      methods: {
          initMaterialLocale() {
              axios.get(api.domain+'material-locale')
                  .then((response) => {
                      this.$material.locale = response.data;
                  }).catch((error) => {
                      console.log('an error occured ' + error);
              });
          },

          toggleSidebar() {
            if (this.$sidebar.showSidebar) {
              this.$sidebar.displaySidebar(false);
            }
          },

          minimizeSidebar() {
            if (this.$sidebar) {
              this.$sidebar.toggleMinimize();
            }
          },

          scrollToTop() {
              if (this.perfectScrollMainPanelContainer) {
                this.perfectScrollMainPanelContainer.scrollTop = 0;
              }
          }
      },

      components: {
        TopNavbar,
        ContentFooter,
        MobileMenu,
        //FixedPlugin,
        //UserMenu,
        ZoomCenterTransition
      }
    }
</script>
