/*
|--------------------------------------------------------------------------
| Mutation Types
|--------------------------------------------------------------------------
*/
export const SET_TOKEN = 'SET_TOKEN';
export const UNSET_TOKEN = 'UNSET_TOKEN';

export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';

export const SET_ROLES = 'SET_ROLES';
export const UNSET_ROLES = 'UNSET_ROLES';

/*
|--------------------------------------------------------------------------
| Initial State
|--------------------------------------------------------------------------
*/
const initialState = {
	jwt_token: null,
	name: null,
	email: null,
	roles: []
};

/*
|--------------------------------------------------------------------------
| Mutations
|--------------------------------------------------------------------------
*/
const mutations = {
	[SET_TOKEN](state, payload) {
		state.jwt_token = payload.access_token;
	},
	[UNSET_TOKEN](state, payload) {
		state.jwt_token = null;
	},

	[SET_USER](state, payload) {
		state.name = payload.user.name;
		state.email = payload.user.email;
		state.roles = payload.user.roles.map((element) => element.name);
	},
	[UNSET_USER](state, payload) {
		state.name = null;
		state.email = null;
		state.roles = [];
	},
	[SET_ROLES](state, payload) {
		state.roles = payload.roles;
	},
	[UNSET_ROLES](state, payload) {
		state.roles = [];
	}
};

/*
|--------------------------------------------------------------------------
| Actions
|--------------------------------------------------------------------------
*/
const actions = {
	setToken: (context, access_token) => {
		context.commit(SET_TOKEN, {access_token});
	},
	removeToken: (context) => {
		context.commit(UNSET_TOKEN);
	},

	setAuthUser: (context, user) => {
		context.commit(SET_USER, {user});
	},
	unsetAuthUser: (context) => {
		context.commit(UNSET_TOKEN);
		context.commit(UNSET_USER);
	},

	setRoles: (context, roles) => {
		context.commit(SET_ROLES, {roles});
	},
	unsetRoles: (context) => {
		context.commit(UNSET_ROLES);
	}
};

/*
|--------------------------------------------------------------------------
| Getters
|--------------------------------------------------------------------------
*/
const getters = {
	getToken: (state) => {
		return state.jwt_token;
	},

	isLoggedIn: (state) => {
		return !!(state.name && state.email);
	},

    getRoles: (state) => {
    	return state.roles;
    },

    hasAnyRole: (state) => (roles) => {
    	let has = false;
    	let numRoles = roles.length;

    	let rolePosition = 0;
    	while (!has && numRoles>rolePosition) {
    		let role = roles[rolePosition];
    		if (state.roles.includes(role)) {
    			has = true;
    		} else {
    			rolePosition++;
    		}
    	}

    	return has;
    }
};

/*
|--------------------------------------------------------------------------
| Export the module
|--------------------------------------------------------------------------
*/
export default {
	state: initialState,
	mutations,
	actions,
	getters
}