<template>
  <div class="autocomplete">
    <input
      class="form-control"
      type="text"
      @input="onChange"
      v-model="dataSearch"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
    />
    <input
      type="hidden"
      @input="handleInput"
      :value="content"
    />
    <ul
      id="autocomplete-results"
      v-show="isOpen"
      class="autocomplete-results"
    >
      <li
        class="loading"
        v-if="isLoading"
      >
        Loading results...
      </li>
      <li
        v-else
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.label }}
      </li>
    </ul>
  </div>
</template>


<script>

 export default {
  name: "autocomplete",
  template: "#autocomplete",
  props: {
    name: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    url: {
      type: String,
      required: true
    },
    value: {
      required: false
    },
    search: {
      type: String,
      required: false
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      isOpen: false,
      results: [],
      dataSearch: "",
      dataValue: "",
      isLoading: false,
      arrowCounter: 0,
      content: this.value
    };
  },

  methods: {
    handleInput (e) {
      this.$emit('input', this.content)
    },

    onChange() {
      // Let's warn the parent that a change was made
      this.$emit("input", this.search);

      // Is the data given by an outside ajax request?
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        // Let's search our flat array
        this.filterResults();
        this.isOpen = true;
      }
    },

    filterResults() {
    //console.log(this.name);
    
       if(this.dataSearch.length>3){
            axios.post(this.url, {txt_search: this.dataSearch})
             .then((response) => {
                 // handle success

                this.results = response.data;
             })
             .catch((error) => {
                 // handle error
                 console.log('an error occured ' + error);
             });
        }else{
            this.results=[];
        }

      
    },
    setResult(result) {
      console.log(result.value);
      this.dataSearch = result.label;
      this.dataValue  = result.value;
      this.content =result.value;
      console.log(result.value);
      this.handleInput ();
      this.isOpen = false;
      this.$emit('change');
    },
    onArrowDown(evt) {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.search = this.results[this.arrowCounter];
      this.isOpen = false;
      this.arrowCounter = -1;
    },
    handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      }
    },
    watch: {
      items: function (val, oldValue) {
        // actually compare them
        if (val.length !== oldValue.length) {
          this.results = val;
          this.isLoading = false;
        }
      },
    },
    mounted() {
      
        this.dataValue=this.value;
        this.dataSearch=this.search;
            
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    }
  };
</script>

<style>
    .autocomplete-results{
        list-style-type: none;
        display: inline-block;
        padding-left:0;
    }
    
    .autocomplete-result{
        border: solid 1px grey;
        float: left;
        clear: left;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top:none;
        background-color:white;
        width:100%;
    }
    
    .autocomplete-result:hover{
        background-color: #e9f3fc;
    }
       
</style>